import * as React from "react"
import type { SVGProps } from "react"
const SvgGame = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M7.292 7.5a.625.625 0 1 0-1.25 0v1.042H5a.625.625 0 1 0 0 1.25h1.042v1.041a.625.625 0 1 0 1.25 0V9.792h1.041a.625.625 0 0 0 0-1.25H7.292z" />
    <path
      fillRule="evenodd"
      d="M11.875 10c0-.345.28-.625.625-.625h.008a.625.625 0 1 1 0 1.25H12.5a.625.625 0 0 1-.625-.625M14.375 8.333c0-.345.28-.625.625-.625h.008a.625.625 0 1 1 0 1.25H15a.625.625 0 0 1-.625-.625"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M2.915 4.561a3.958 3.958 0 0 1 2.652-1.02h8.866a3.959 3.959 0 0 1 3.937 3.55l.007.067a.71.71 0 0 0 .005.04l.001.012c.064.536.575 4.788.575 6.123a3.125 3.125 0 0 1-3.125 3.125c-1.092 0-1.669-.576-2.09-.998l-.018-.018-1.179-1.178a1.042 1.042 0 0 0-.736-.306H8.19c-.276 0-.541.11-.736.306L6.258 15.46c-.422.422-.999.998-2.091.998a3.125 3.125 0 0 1-3.125-3.125c0-1.335.51-5.587.575-6.123l.001-.01a3.958 3.958 0 0 1 1.297-2.639m2.652.23a2.708 2.708 0 0 0-2.694 2.431v.008l-.002.018-.013.116c-.073.603-.566 4.734-.566 5.97a1.875 1.875 0 0 0 1.875 1.874c.558 0 .814-.24 1.224-.65L6.57 13.38a2.292 2.292 0 0 1 1.62-.672h3.62c.608 0 1.19.242 1.62.672l1.179 1.178c.41.41.666.65 1.224.65a1.875 1.875 0 0 0 1.875-1.875c0-1.236-.493-5.365-.566-5.969a5.276 5.276 0 0 1-.013-.122l-.001-.01-.002-.009a2.708 2.708 0 0 0-2.693-2.431m0 0H5.567z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgGame
