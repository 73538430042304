import * as React from "react"
import type { SVGProps } from "react"
const SvgFeatured = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10 2.292a4.375 4.375 0 1 0 0 8.75 4.375 4.375 0 0 0 0-8.75M4.375 6.667a5.625 5.625 0 1 1 11.25 0 5.625 5.625 0 0 1-11.25 0"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M7.206 10.125c.34.057.57.38.513.72L6.673 17.1l3.005-1.804a.625.625 0 0 1 .644 0l3.005 1.804-1.046-6.256a.625.625 0 1 1 1.233-.206l1.27 7.591a.625.625 0 0 1-.939.64L10 16.561 6.155 18.87a.625.625 0 0 1-.938-.639l1.269-7.591c.057-.34.38-.57.72-.514"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgFeatured
