import * as React from "react"
import type { SVGProps } from "react"
const SvgSports = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M18.934 9.338a8.924 8.924 0 0 0-2.6-5.673 8.924 8.924 0 0 0-5.672-2.599.63.63 0 0 0-.226-.014 8.935 8.935 0 0 0-6.77 2.613 8.935 8.935 0 0 0-2.614 6.77.627.627 0 0 0 .014.227 8.924 8.924 0 0 0 2.6 5.672 8.923 8.923 0 0 0 5.672 2.6.631.631 0 0 0 .227.014 8.936 8.936 0 0 0 6.77-2.614 8.935 8.935 0 0 0 2.613-6.77.629.629 0 0 0-.014-.226m-3.483-4.789a7.674 7.674 0 0 0-4.405-2.187 10.58 10.58 0 0 1-2.961 5.723 10.58 10.58 0 0 1-5.722 2.96 7.675 7.675 0 0 0 2.186 4.406 7.674 7.674 0 0 0 4.405 2.186 10.58 10.58 0 0 1 2.961-5.722 10.58 10.58 0 0 1 5.723-2.96 7.675 7.675 0 0 0-2.187-4.406m2.254 5.663a9.33 9.33 0 0 0-4.906 2.587 9.33 9.33 0 0 0-2.587 4.906 7.682 7.682 0 0 0 5.239-2.254 7.682 7.682 0 0 0 2.254-5.24M4.55 4.549a7.682 7.682 0 0 0-2.254 5.24A9.329 9.329 0 0 0 7.2 7.2a9.329 9.329 0 0 0 2.587-4.906A7.682 7.682 0 0 0 4.55 4.549"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgSports
