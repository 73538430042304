import * as React from "react"
import type { SVGProps } from "react"
const SvgReference = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M6.667 4.375A.625.625 0 0 0 6.042 5v3.333c0 .345.28.625.625.625h6.666c.345 0 .625-.28.625-.625V5a.625.625 0 0 0-.625-.625zm.625 3.333V5.625h5.416v2.083z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M17.292 1.667v16.666c0 .346-.28.625-.625.625H5.417a2.708 2.708 0 0 1-2.709-2.708V3.75a2.708 2.708 0 0 1 2.709-2.708h11.25c.345 0 .625.28.625.625m-11.875.625A1.458 1.458 0 0 0 3.958 3.75v10.218a2.709 2.709 0 0 1 1.459-.426h10.625V2.292zm-1.032 14.99a1.458 1.458 0 0 1 1.032-2.49h10.625v2.916H5.417c-.387 0-.758-.153-1.032-.427"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgReference
