import * as React from "react"
import type { SVGProps } from "react"
const SvgUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M2.201 13.034A3.958 3.958 0 0 1 5 11.875h5a3.958 3.958 0 0 1 3.958 3.958V17.5a.625.625 0 1 1-1.25 0v-1.667A2.708 2.708 0 0 0 10 13.125H5a2.708 2.708 0 0 0-2.708 2.708V17.5a.625.625 0 1 1-1.25 0v-1.667c0-1.05.417-2.056 1.159-2.799M7.5 3.125a2.708 2.708 0 1 0 0 5.417 2.708 2.708 0 0 0 0-5.417M3.542 5.833a3.958 3.958 0 1 1 7.916 0 3.958 3.958 0 0 1-7.916 0M15.228 12.452a.625.625 0 0 1 .762-.449 3.958 3.958 0 0 1 2.968 3.83V17.5a.625.625 0 1 1-1.25 0v-1.666a2.709 2.709 0 0 0-2.03-2.62.625.625 0 0 1-.45-.762M12.728 2.453a.625.625 0 0 1 .76-.45 3.958 3.958 0 0 1 0 7.67.625.625 0 0 1-.31-1.212 2.709 2.709 0 0 0 0-5.247.625.625 0 0 1-.45-.76"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgUsers
