import * as React from "react"
import type { SVGProps } from "react"
const SvgShopping = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M7.292 8.333a.625.625 0 0 0-1.25 0 3.958 3.958 0 1 0 7.916 0 .625.625 0 1 0-1.25 0 2.708 2.708 0 1 1-5.416 0" />
    <path
      fillRule="evenodd"
      d="M4.5 1.292a.625.625 0 0 1 .5-.25h10c.197 0 .382.092.5.25l2.496 3.328a.622.622 0 0 1 .129.38v11.667a2.291 2.291 0 0 1-2.292 2.291H4.167a2.292 2.292 0 0 1-2.292-2.291V5c0-.143.048-.275.129-.38zm10.188 1 1.562 2.083H3.75l1.563-2.083zM3.124 16.667V5.625h13.75v11.042a1.041 1.041 0 0 1-1.042 1.041H4.167a1.041 1.041 0 0 1-1.042-1.041"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgShopping
