import * as React from "react"
import type { SVGProps } from "react"
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M9.167 3.125a6.042 6.042 0 1 0 0 12.083 6.042 6.042 0 0 0 0-12.083M1.875 9.167a7.292 7.292 0 1 1 14.583 0 7.292 7.292 0 0 1-14.583 0"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M13.433 13.433a.625.625 0 0 1 .884 0l3.625 3.625a.625.625 0 1 1-.884.884l-3.625-3.625a.625.625 0 0 1 0-.884"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgSearch
