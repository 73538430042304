import * as React from "react"
import type { SVGProps } from "react"
const SvgUtilities = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10 1.043c-.402 0-.796.106-1.145.307L6.16 2.89a.625.625 0 0 0-.397.227l-2.74 1.565-.002.002a2.292 2.292 0 0 0-1.146 1.983v6.667a2.292 2.292 0 0 0 1.146 1.982l.002.002 5.831 3.332h.001a2.292 2.292 0 0 0 2.29 0l5.832-3.332.002-.002a2.291 2.291 0 0 0 1.146-1.983V6.666a2.292 2.292 0 0 0-1.146-1.982L11.146 1.35h-.002A2.292 2.292 0 0 0 10 1.043m.625 16.465 5.73-3.274a1.041 1.041 0 0 0 .52-.901V6.774l-6.25 3.588zm-1.25-7.146-6.25-3.588v6.56a1.042 1.042 0 0 0 .52.9l5.73 3.274zM6.292 4.254 3.763 5.7 10 9.28l2.509-1.44zm4.229-1.821 5.716 3.266-2.474 1.42-6.214-3.583 1.928-1.102.002-.001a1.042 1.042 0 0 1 1.042 0"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgUtilities
