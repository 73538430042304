import * as React from "react"
import type { SVGProps } from "react"
const SvgApps = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M1.975 5.473a3.498 3.498 0 1 1 6.996 0 3.498 3.498 0 0 1-6.996 0M5.473 3.21a2.263 2.263 0 1 0 0 4.527 2.263 2.263 0 0 0 0-4.527M11.029 14.527a3.498 3.498 0 1 1 6.996 0 3.498 3.498 0 0 1-6.996 0m3.498-2.264a2.263 2.263 0 1 0 0 4.527 2.263 2.263 0 0 0 0-4.527M1.975 14.527a3.498 3.498 0 1 1 6.996 0 3.498 3.498 0 0 1-6.996 0m3.498-2.264a2.263 2.263 0 1 0 0 4.527 2.263 2.263 0 0 0 0-4.527M14.527 1.975c.34 0 .617.277.617.618v5.76a.617.617 0 1 1-1.235 0v-5.76c0-.341.277-.618.618-.618"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M11.029 5.473c0-.34.276-.617.617-.617h5.761a.617.617 0 0 1 0 1.235h-5.76a.617.617 0 0 1-.618-.618"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgApps
