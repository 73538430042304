import * as React from "react"
import type { SVGProps } from "react"
const SvgFitness = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M12.5 2.708a1.458 1.458 0 1 0 0 2.917 1.458 1.458 0 0 0 0-2.917m-.147 1.311a.208.208 0 1 1 .294.294.208.208 0 0 1-.294-.294"
      clipRule="evenodd"
    />
    <path d="M11.353 6.32a.625.625 0 0 0-.895-.153l-3.333 2.5a.625.625 0 0 0-.067.942l2.317 2.316v2.658a.625.625 0 1 0 1.25 0v-2.916a.625.625 0 0 0-.183-.442l-1.99-1.99 2.228-1.672 1.3 1.95a.625.625 0 0 0 .52.279h1.667a.625.625 0 0 0 0-1.25h-1.332z" />
    <path
      fillRule="evenodd"
      d="M1.042 14.583a3.542 3.542 0 1 1 7.083 0 3.542 3.542 0 0 1-7.083 0m3.541-2.291a2.292 2.292 0 1 0 0 4.583 2.292 2.292 0 0 0 0-4.583M11.875 14.583a3.542 3.542 0 1 1 7.083 0 3.542 3.542 0 0 1-7.083 0m3.542-2.291a2.292 2.292 0 1 0 0 4.583 2.292 2.292 0 0 0 0-4.583"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgFitness
