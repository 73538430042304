import * as React from "react"
import type { SVGProps } from "react"
const SvgCrypto = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M5.208 5c0-.345.28-.625.625-.625h.834c.345 0 .625.28.625.625v3.333a.625.625 0 1 1-1.25 0V5.625h-.209A.625.625 0 0 1 5.208 5" />
    <path
      fillRule="evenodd"
      d="M6.667 1.042a5.625 5.625 0 1 0 0 11.25 5.625 5.625 0 0 0 0-11.25M2.292 6.667a4.375 4.375 0 1 1 8.75 0 4.375 4.375 0 0 1-8.75 0"
      clipRule="evenodd"
    />
    <path d="M15.293 8.056a.625.625 0 1 0-.436 1.171 4.374 4.374 0 1 1-5.651 5.564.625.625 0 1 0-1.178.418 5.625 5.625 0 1 0 7.265-7.153" />
    <path d="M14.37 11.128a.625.625 0 0 0-.89.877l.148.15-1.912 1.911a.625.625 0 1 0 .884.884l2.35-2.35a.625.625 0 0 0 .003-.88z" />
  </svg>
)
export default SvgCrypto
