import * as React from "react"
import type { SVGProps } from "react"
const SvgEntertainment = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M12.761 6.939 8.606 8.212a.621.621 0 0 1-.218.067l-.856.263h9.135c.345 0 .625.28.625.625v6.666A2.292 2.292 0 0 1 15 18.125H5a2.292 2.292 0 0 1-2.292-2.292V9.26l-.706-2.302a2.292 2.292 0 0 1 1.524-2.864l9.565-2.916a2.291 2.291 0 0 1 2.864 1.512l.002.004.724 2.391c.1.33-.085.678-.415.78l-3.268 1.001a.62.62 0 0 1-.237.073m.698-4.567a1.042 1.042 0 0 1 1.302.686v.002l.544 1.792-2.392.733-1.968-2.446 2.512-.766zm-3.803 1.16-3.098.944 1.973 2.452 3.095-.949zM5.27 4.869l1.974 2.453L3.75 8.394l-.552-1.802A1.042 1.042 0 0 1 3.89 5.29zM3.958 15.833V9.792h12.084v6.041A1.042 1.042 0 0 1 15 16.875H5a1.042 1.042 0 0 1-1.042-1.042"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgEntertainment
