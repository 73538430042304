import * as React from "react"
import type { SVGProps } from "react"
const SvgProductivity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M14.233 2.383a4.375 4.375 0 0 0-4.889 6.084.625.625 0 0 1-.127.7l-5.758 5.758a1.143 1.143 0 1 0 1.616 1.616l5.758-5.758a.625.625 0 0 1 .7-.127 4.375 4.375 0 0 0 6.084-4.89l-2.43 2.43a1.458 1.458 0 0 1-2.041 0l-.005-.004-1.337-1.338a1.458 1.458 0 0 1 0-2.041l.004-.005zM12.322 1.13a5.625 5.625 0 0 1 3.327.409.625.625 0 0 1 .185 1.011l-3.14 3.139a.208.208 0 0 0 0 .289l1.328 1.327a.208.208 0 0 0 .289 0l3.139-3.139a.625.625 0 0 1 1.011.185 5.626 5.626 0 0 1-7.037 7.609L5.96 17.425a2.393 2.393 0 0 1-3.384-3.384L8.04 8.576a5.625 5.625 0 0 1 4.282-7.446"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgProductivity
