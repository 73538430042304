import * as React from "react"
import type { SVGProps } from "react"
const SvgFinance = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10.331 1.137a.625.625 0 0 0-.662 0L3.002 5.303a.625.625 0 0 0 .331 1.155h13.334a.625.625 0 0 0 .33-1.155zM10 2.404l4.487 2.804H5.513z"
      clipRule="evenodd"
    />
    <path d="M5.625 9.167a.625.625 0 1 0-1.25 0V15a.625.625 0 1 0 1.25 0zM2.5 17.708a.625.625 0 1 0 0 1.25h15a.625.625 0 0 0 0-1.25zM8.333 8.542c.346 0 .625.28.625.625V15a.625.625 0 1 1-1.25 0V9.167c0-.345.28-.625.625-.625M12.292 9.167a.625.625 0 0 0-1.25 0V15a.625.625 0 1 0 1.25 0zM15 8.542c.345 0 .625.28.625.625V15a.625.625 0 1 1-1.25 0V9.167c0-.345.28-.625.625-.625" />
  </svg>
)
export default SvgFinance
