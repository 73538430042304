import * as React from "react"
import type { SVGProps } from "react"
const SvgBriefcase = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M8.333 1.875a2.292 2.292 0 0 0-2.291 2.292v1.041H3.333A2.292 2.292 0 0 0 1.042 7.5v8.333a2.292 2.292 0 0 0 2.291 2.292h13.334a2.292 2.292 0 0 0 2.291-2.292V7.5a2.292 2.292 0 0 0-2.291-2.292h-2.709V4.167a2.292 2.292 0 0 0-2.291-2.292zm4.375 3.333V4.167a1.041 1.041 0 0 0-1.041-1.042H8.333a1.042 1.042 0 0 0-1.041 1.042v1.041zm-5.416 1.25h5.416v10.417H7.292zm-1.25 0H3.333c-.575 0-1.041.467-1.041 1.042v8.333c0 .576.466 1.042 1.041 1.042h2.709zm7.916 10.417V6.458h2.709c.575 0 1.041.467 1.041 1.042v8.333c0 .576-.466 1.042-1.041 1.042z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgBriefcase
