import * as React from "react"
import type { SVGProps } from "react"
const SvgWeather = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M10.625 1.667a.625.625 0 1 0-1.25 0v1.666a.625.625 0 1 0 1.25 0zM4.55 3.666a.625.625 0 0 0-.884.884l1.175 1.175a.625.625 0 1 0 .884-.884zM16.042 10c0-.345.28-.625.625-.625h1.666a.625.625 0 1 1 0 1.25h-1.666a.625.625 0 0 1-.625-.625M16.334 4.55a.625.625 0 1 0-.884-.884l-1.175 1.175a.625.625 0 1 0 .884.884zM8.043 6.558a3.958 3.958 0 0 1 5.863 4.085.625.625 0 1 1-1.234-.203 2.709 2.709 0 0 0-4.011-2.795.625.625 0 1 1-.618-1.087" />
    <path
      fillRule="evenodd"
      d="M6.981 9.514a4.792 4.792 0 1 0-1.148 9.444h5a3.125 3.125 0 1 0 0-6.25H10.4A4.791 4.791 0 0 0 6.98 9.514m-2.268 1.293a3.542 3.542 0 0 1 4.591 2.651c.06.291.316.5.613.5h.916a1.875 1.875 0 1 1 0 3.75h-5a3.542 3.542 0 0 1-1.12-6.9"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgWeather
