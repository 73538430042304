import * as React from "react"
import type { SVGProps } from "react"
const SvgKids = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M7.167 11.292a.625.625 0 1 0-1 .75c.485.647 1.9 1.916 3.833 1.916 1.933 0 3.348-1.269 3.833-1.916a.625.625 0 1 0-1-.75c-.347.463-1.433 1.416-2.833 1.416-1.4 0-2.486-.953-2.833-1.416M6.875 7.5c0-.345.28-.625.625-.625h.008a.625.625 0 1 1 0 1.25H7.5a.625.625 0 0 1-.625-.625M12.5 6.875a.625.625 0 1 0 0 1.25h.008a.625.625 0 1 0 0-1.25z" />
    <path
      fillRule="evenodd"
      d="M1.042 10a8.958 8.958 0 1 1 17.916 0 8.958 8.958 0 0 1-17.916 0M10 2.292a7.708 7.708 0 1 0 0 15.416 7.708 7.708 0 0 0 0-15.416"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgKids
