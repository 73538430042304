import * as React from "react"
import type { SVGProps } from "react"
const SvgMedia = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M9.046 18.908a8.945 8.945 0 0 0 8.124-3.536.618.618 0 0 0 .077-.104A8.918 8.918 0 0 0 18.958 10a8.93 8.93 0 0 0-.763-3.625A8.967 8.967 0 0 0 10 1.042a8.945 8.945 0 0 0-7.17 3.586.622.622 0 0 0-.077.104A8.918 8.918 0 0 0 1.042 10a8.929 8.929 0 0 0 .763 3.625 8.967 8.967 0 0 0 7.24 5.283m.112-12.866 2.106-3.647a7.714 7.714 0 0 1 5.352 3.647H11.913zM6.15 8.75l3.729-6.457a7.693 7.693 0 0 0-5.834 2.812zm-.533 1.577 1.375 2.381H2.781A7.692 7.692 0 0 1 2.29 10c0-1.361.353-2.64.972-3.75l2.338 4.05zm5.225 3.631H3.384a7.714 7.714 0 0 0 5.352 3.647zm.722-1.25H8.436L6.872 10l1.564-2.708h3.128L13.128 10zm-1.443 5a7.693 7.693 0 0 0 5.834-2.813L13.85 11.25zM14.399 9.7l2.338 4.048A7.674 7.674 0 0 0 17.708 10a7.69 7.69 0 0 0-.489-2.708h-4.211l1.375 2.381z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgMedia
