import * as React from "react"
import type { SVGProps } from "react"
const SvgNews = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M8.333 11.042a.625.625 0 1 0 0 1.25H15a.625.625 0 1 0 0-1.25zM7.708 15c0-.345.28-.625.625-.625H12.5a.625.625 0 1 1 0 1.25H8.333A.625.625 0 0 1 7.708 15" />
    <path
      fillRule="evenodd"
      d="M8.333 4.375A.625.625 0 0 0 7.708 5v3.333c0 .345.28.625.625.625H15c.345 0 .625-.28.625-.625V5A.625.625 0 0 0 15 4.375zm.625 3.333V5.625h5.417v2.083z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M6.667 1.042a2.292 2.292 0 0 0-2.292 2.291v3.542H3.333a2.297 2.297 0 0 0-2.291 2.292v7.5a2.292 2.292 0 0 0 2.291 2.291h13.334a2.292 2.292 0 0 0 2.291-2.291V3.333a2.292 2.292 0 0 0-2.291-2.291zM3.333 8.125h1.042v8.542a1.042 1.042 0 0 1-2.083 0v-7.5c0-.572.47-1.042 1.041-1.042m2.292 8.542c0 .365-.087.722-.25 1.041h11.292a1.042 1.042 0 0 0 1.041-1.041V3.333a1.041 1.041 0 0 0-1.041-1.041h-10a1.042 1.042 0 0 0-1.042 1.041z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgNews
