import * as React from "react"
import type { SVGProps } from "react"
const SvgMusic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M18.125 2.5a.625.625 0 0 0-.728-.616l-10 1.666a.625.625 0 0 0-.522.617V12.5a3.125 3.125 0 1 0 1.25 2.5V8.03l8.75-1.459v4.262a3.125 3.125 0 1 0 1.25 2.502V5.823zm-1.25 10.833a1.875 1.875 0 1 0-3.75 0 1.875 1.875 0 0 0 3.75 0M5 13.125a1.875 1.875 0 1 1 0 3.75 1.875 1.875 0 0 1 0-3.75m11.875-7.821V3.238l-8.75 1.458v2.066z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgMusic
