import * as React from "react"
import type { SVGProps } from "react"
const SvgSparkles = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M12 2.25a.75.75 0 0 1 .713.516l1.912 5.813a1.25 1.25 0 0 0 .796.797l5.813 1.912a.75.75 0 0 1 0 1.425l-5.813 1.912a1.25 1.25 0 0 0-.796.796l-1.912 5.813a.75.75 0 0 1-1.425 0l-1.912-5.813a1.25 1.25 0 0 0-.797-.796l-5.813-1.912a.75.75 0 0 1 0-1.425l5.813-1.912a1.25 1.25 0 0 0 .797-.797l1.912-5.813A.75.75 0 0 1 12 2.25m-1.2 6.797A2.751 2.751 0 0 1 9.048 10.8L5.4 12l3.647 1.2a2.751 2.751 0 0 1 1.753 1.753L12 18.6l1.2-3.647a2.75 2.75 0 0 1 1.753-1.753L18.6 12l-3.647-1.2A2.75 2.75 0 0 1 13.2 9.048L12 5.4z"
      clipRule="evenodd"
    />
    <path d="M5 2.25a.75.75 0 0 1 .75.75v1.25H7a.75.75 0 0 1 0 1.5H5.75V7a.75.75 0 0 1-1.5 0V5.75H3a.75.75 0 0 1 0-1.5h1.25V3A.75.75 0 0 1 5 2.25M19.75 17a.75.75 0 0 0-1.5 0v1.25H17a.75.75 0 0 0 0 1.5h1.25V21a.75.75 0 0 0 1.5 0v-1.25H21a.75.75 0 0 0 0-1.5h-1.25z" />
  </svg>
)
export default SvgSparkles
