import * as React from "react"
import type { SVGProps } from "react"
const SvgHammer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10.442 7.058a.625.625 0 0 1 0 .884l-7.083 7.083a1.143 1.143 0 1 0 1.616 1.616l7.083-7.083a.625.625 0 1 1 .884.884l-7.083 7.083a2.39 2.39 0 0 1-3.903-.776 2.392 2.392 0 0 1 .519-2.608l7.083-7.083a.625.625 0 0 1 .884 0M18.775 8.425a.625.625 0 0 1 0 .884l-3.633 3.633a.625.625 0 0 1-.884-.884l3.633-3.633a.625.625 0 0 1 .884 0"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M6.916 2.244a.625.625 0 0 1 .584-.402h2.558a5.26 5.26 0 0 1 3.727 1.55l2.267 2.284a.625.625 0 0 1 .181.44v.717c0 .544.21 1.051.592 1.433l1.042 1.042a.625.625 0 0 1-.001.885l-1.817 1.808a.625.625 0 0 1-.844.035l-1.709-1.444h-1.83a.625.625 0 0 1-.441-.183L9.558 8.742a.625.625 0 0 1-.183-.442V7a4.527 4.527 0 0 0-1.523-3.382l-.001-.001-.767-.684a.625.625 0 0 1-.168-.689m2.182.848A5.775 5.775 0 0 1 10.625 7V8.04l1.3 1.3h1.8c.148 0 .29.053.403.148l1.446 1.222.966-.962-.599-.599a3.26 3.26 0 0 1-.958-2.317v-.459l-2.085-2.1a4.008 4.008 0 0 0-2.84-1.182m0 0h-.96Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgHammer
