import * as React from "react"
import type { SVGProps } from "react"
const SvgWand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M15.621 1.169a1.633 1.633 0 0 1 1.789.357v.001l1.063 1.062a1.627 1.627 0 0 1 0 2.322L4.91 18.473a1.626 1.626 0 0 1-2.322 0L1.527 17.41l-.001-.001a1.633 1.633 0 0 1 0-2.32h.001L15.089 1.526l.001-.001c.152-.153.332-.274.531-.357m2.412 1.864-.442.442-1.066-1.066-.002-.003a.384.384 0 0 0-.545 0l-.003.003L2.41 15.975l-.003.003a.382.382 0 0 0 0 .545l.003.002 1.072 1.072a.375.375 0 0 0 .538 0l.006-.006L17.597 4.02a.375.375 0 0 0 0-.538z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M11.225 5.391a.625.625 0 0 1 .884 0l2.5 2.5a.625.625 0 1 1-.884.884l-2.5-2.5a.625.625 0 0 1 0-.884M4.167 4.375c.345 0 .625.28.625.625v3.333a.625.625 0 1 1-1.25 0V5c0-.345.28-.625.625-.625M15.833 11.042c.345 0 .625.28.625.625V15a.625.625 0 1 1-1.25 0v-3.333c0-.345.28-.625.625-.625M8.333 1.042c.346 0 .625.28.625.625v1.666a.625.625 0 1 1-1.25 0V1.667c0-.346.28-.625.625-.625"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M1.875 6.667c0-.346.28-.625.625-.625h3.333a.625.625 0 1 1 0 1.25H2.5a.625.625 0 0 1-.625-.625M13.542 13.333c0-.345.28-.625.625-.625H17.5a.625.625 0 0 1 0 1.25h-3.333a.625.625 0 0 1-.625-.625M6.875 2.5c0-.345.28-.625.625-.625h1.667a.625.625 0 1 1 0 1.25H7.5a.625.625 0 0 1-.625-.625"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgWand
