import * as React from "react"
import type { SVGProps } from "react"
const SvgTravel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M7.5 1.875c.105 0 .204.026.29.072L12.5 4.3l4.72-2.36a.625.625 0 0 1 .905.559V15a.625.625 0 0 1-.346.559l-4.988 2.494a.62.62 0 0 1-.582 0L7.5 15.7l-4.72 2.36a.625.625 0 0 1-.905-.559V5c0-.237.134-.453.345-.559l4.99-2.494a.622.622 0 0 1 .29-.072m5.625 14.614 3.75-1.875V3.51l-3.75 1.875zm-1.25-11.103V16.49l-3.75-1.875V3.51zm-8.75 0 3.75-1.875v11.103l-3.75 1.875z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgTravel
