import * as React from "react"
import type { SVGProps } from "react"
const SvgMedical = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M4.093 1.042a.875.875 0 1 1-.627 1.25h-.133a1.042 1.042 0 0 0-1.041 1.041V7.5a4.375 4.375 0 0 0 4.37 4.375H6.67a4.375 4.375 0 0 0 4.37-4.375V3.333A1.042 1.042 0 0 0 10 2.292h-.083a.802.802 0 1 1-1.192-1.067.625.625 0 0 1 .442-.183H10a2.291 2.291 0 0 1 2.292 2.291V7.5a5.625 5.625 0 0 1-5 5.59v.243a4.375 4.375 0 0 0 8.75 0V10.54a2.293 2.293 0 1 1 1.25 0v2.794a5.625 5.625 0 0 1-11.25 0v-.243a5.626 5.626 0 0 1-5-5.59V3.333a2.292 2.292 0 0 1 2.291-2.291zm11.532 7.291a1.042 1.042 0 1 1 2.083 0 1.042 1.042 0 0 1-2.083 0"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgMedical
