import {
  SvgApps,
  SvgBriefcase,
  SvgCrypto,
  SvgDesign,
  SvgEducation,
  SvgEntertainment,
  SvgFeatured,
  SvgFinance,
  SvgFitness,
  SvgFood,
  SvgGame,
  SvgHammer,
  SvgKids,
  SvgLifestyle,
  SvgMedia,
  SvgMedical,
  SvgMusic,
  SvgNavigation,
  SvgNews,
  SvgProductivity,
  SvgReference,
  SvgShopping,
  SvgSparkles,
  SvgSports,
  SvgTravel,
  SvgUsers,
  SvgUtilities,
  SvgWand,
  SvgWeather,
} from "@store-platform/ui/icons"
import {
  Category,
  CategoryList,
  PrimaryCategory,
  SubCategory,
} from "@store-platform/types"
import { omit } from "lodash-es"

const SeoDefault = {
  title: "Store.app - Explore Modern Web Apps & PWAs",
  description: {
    long: "The premier hub for web apps and PWAs. Unleash the full potential of the open web with a rich suite of features for users and developers alike",
  },
}

export const categoryFeatured: PrimaryCategory = {
  id: "featured",
  name: "Featured",
  icon: SvgFeatured,
  description: {
    title: "Featured Web Apps & PWAs",
    short: "Trending and featured apps on Store.app",
    long: SeoDefault.description.long,
  },
  position: 0,
}

export const categoryAll: PrimaryCategory = {
  id: "all",
  name: "All Apps",
  icon: SvgApps,
  description: {
    title: "All Web Apps & PWAs",
    short: "Browse through all of the apps on Store.app",
    long: "Dive into a world of endless possibilities. Browse and choose from a diverse collection of apps to suit every need.",
  },
  position: 1,
}

export const categoryNew: PrimaryCategory = {
  id: "newest",
  name: "New",
  icon: SvgSparkles,
  description: {
    title: "Best New Web Apps & PWAs",
    short: "The latest apps published on Store.app",
    long: "Be the first to experience the latest arrivals. Explore fresh and exciting additions to our ever-growing app library.",
  },
  position: 2,
}

export const Categories: CategoryList = {
  [categoryFeatured.id]: categoryFeatured,
  [categoryAll.id]: categoryAll,
  [categoryNew.id]: categoryNew,
  ai: {
    id: "ai",
    name: "AI",
    icon: SvgWand,

    description: {
      short: "Elevate your tech experience with cutting-edge AI innovations",
      long: "Delve into AI technology with apps for virtual assistance, data analytics, and educational tools, tailored for both personal and professional growth.",
    },
    position: 3,
  },
  business: {
    id: "business",
    name: "Business",
    icon: SvgBriefcase,
    description: {
      short: "Boost productivity with top business solutions",
      long: "Streamline operations and boost efficiency with our curated selection of top-notch business solutions.",
    },
    position: 4,
  },
  crypto: {
    id: "crypto",
    name: "Crypto",
    icon: SvgCrypto,
    description: {
      short: "Explore the world of cryptocurrencies and blockchain",
      long: "Navigate the world of digital finance effortlessly. Explore blockchain technology and cryptocurrency trends.",
    },
    position: 5,
    subCategories: {
      all: {
        id: "all",
        name: "All Crypto",
        position: 0,
      },
      analytics: {
        id: "analytics",
        name: "Analytics",
        position: 1,
      },
      aptos: {
        id: "aptos",
        name: "Aptos",
        position: 2,
      },
      arbitrum: {
        id: "arbitrum",
        name: "Arbitrum",
        position: 3,
      },
      avalanche: {
        id: "avalanche",
        name: "Avalanche",
        position: 4,
      },
      base: {
        id: "base",
        name: "Base",
        position: 5,
      },
      bnb: {
        id: "bnb",
        name: "BNB",
        position: 6,
      },
      btc: {
        id: "btc",
        name: "Bitcoin",
        position: 7,
      },
      chia: {
        id: "chia",
        name: "Chia",
        position: 8,
      },
      cosmos: {
        id: "cosmos",
        name: "Cosmos",
        position: 9,
      },
      exchanges: {
        id: "exchanges",
        name: "Exchanges",
        position: 10,
      },
      flow: {
        id: "flow",
        name: "Flow",
        position: 11,
      },
      layerzero: {
        id: "layerzero",
        name: "LayerZero",
        position: 12,
      },
      optimism: {
        id: "optimism",
        name: "Optimism",
        position: 13,
      },
      polkadot: {
        id: "polkadot",
        name: "Polkadot",
        position: 14,
      },
      polygon: {
        id: "polygon",
        name: "Polygon",
        position: 15,
      },
      ripple: {
        id: "ripple",
        name: "Ripple",
        position: 16,
      },
      solana: {
        id: "solana",
        name: "Solana",
        position: 17,
      },
      xrp: {
        id: "xrp",
        name: "XRP",
        position: 18,
      },
      other: {
        id: "other",
        name: "Other",
        position: 19,
      },
    },
  },
  games: {
    id: "games",
    name: "Games",
    icon: SvgGame,
    description: {
      short: "Immerse yourself in a world of gaming excitement",
      long: "Immerse yourself in a gaming wonderland. From thrilling adventures to strategic challenges, find your favorite games.",
    },
    position: 6,
    subCategories: {
      all: {
        id: "all",
        name: "All Games",
        position: 0,
      },
      multiplayer: {
        id: "multiplayer",
        name: "Multiplayer",
        position: 1,
      },
      singleplayer: {
        id: "singleplayer",
        name: "Singleplayer",
        position: 2,
      },
      boardgame: {
        id: "boardgame",
        name: "Boardgame",
        position: 3,
      },
      strategy: {
        id: "strategy",
        name: "Strategy",
        position: 4,
      },
    },
  },
  "developer-tools": {
    id: "developer-tools",
    name: "Dev Tools",
    icon: SvgHammer,
    description: {
      short: "Essential tools for developers and coders",
      long: "Gain access to an extensive array of development tools, including IDEs, libraries, and testing software, for all types of projects.",
    },
    position: 7,
  },
  education: {
    id: "education",
    name: "Education",
    icon: SvgEducation,
    description: {
      short: "Learning made fun and accessible for all ages",
      long: "Transform learning into an engaging adventure. Explore a diverse range of educational apps for all ages.",
    },
    position: 8,
  },
  entertainment: {
    id: "entertainment",
    name: "Entertainment",
    icon: SvgEntertainment,
    description: {
      short: "Endless entertainment options at your fingertips",
      long: "Unlock a universe of entertainment options. From movies to music, discover content tailored to your tastes.",
    },
    position: 9,
  },
  finance: {
    id: "finance",
    name: "Finance",
    icon: SvgFinance,
    description: {
      short: "Manage your finances and investments effortlessly",
      long: "Take control of your financial journey. Manage budgets, track expenses, and invest wisely with finance apps.",
    },
    position: 10,
  },
  "food-drink": {
    id: "food-drink",
    name: "Food & Drink",
    icon: SvgFood,
    description: {
      short: "Discover recipes and dining experiences",
      long: "Savor every moment with our Food & Drink category. Discover recipes, dining tips, and culinary delights.",
    },
    position: 11,
  },
  "graphics-design": {
    id: "graphics-design",
    name: "Graphics & Design",
    icon: SvgDesign,
    description: {
      short: "Unleash your creativity with design tools",
      long: "Discover a broad spectrum of graphic design apps, from drawing to layout, catering to creatives of every skill level.",
    },
    position: 12,
  },
  "health-fitness": {
    id: "health-fitness",
    name: "Health & Fitness",
    icon: SvgFitness,
    description: {
      short: "Achieve your wellness goals with fitness apps",
      long: "Explore apps for health monitoring, fitness tracking, and wellness advice, suitable for all fitness levels and wellness goals.",
    },
    position: 13,
  },
  kids: {
    id: "kids",
    name: "Kids",
    icon: SvgKids,
    description: {
      short: "Safe and engaging apps for young minds to explore",
      long: "Provide a safe and enriching digital space for young minds. Discover age-appropriate and engaging Kids apps.",
    },
    position: 14,
  },
  lifestyle: {
    id: "lifestyle",
    name: "Lifestyle",
    icon: SvgLifestyle,
    description: {
      short: "Enhance your lifestyle with curated apps",
      long: "Elevate your lifestyle. From hobbies to personal interests, explore apps that complement your way of life.",
    },
    position: 15,
  },
  medical: {
    id: "medical",
    name: "Medical",
    icon: SvgMedical,
    description: {
      short: "Access medical information and tools on-the-go",
      long: "Access vital health information on the go. Stay informed with our Medical category's valuable resources.",
    },
    position: 16,
  },
  music: {
    id: "music",
    name: "Music",
    icon: SvgMusic,
    description: {
      short: "Dive into a world of melodies and beats",
      long: "Dive into a symphony of sounds. Explore genres, discover artists, and curate your perfect musical experience.",
    },
    position: 17,
  },
  navigation: {
    id: "navigation",
    name: "Navigation",
    icon: SvgNavigation,
    description: {
      short: "Navigate with ease using top-notch navigation apps",
      long: "Navigate with confidence. Discover top-notch navigation apps for a seamless travel and exploration experience.",
    },
    position: 18,
  },
  news: {
    id: "news",
    name: "News",
    icon: SvgNews,
    description: {
      short: "Stay informed with the latest news and updates",
      long: "Stay informed and updated. Dive into our News category for the latest headlines and breaking stories.",
    },
    position: 19,
  },
  "photo-video": {
    id: "photo_video",
    name: "Photo & Video",
    icon: SvgMedia,
    description: {
      short: "Capture, edit, and share memorable moments",
      long: "Browse through a vast selection of apps for photo and video capturing, editing, and sharing, designed for both novices and experts.",
    },
    position: 20,
  },
  productivity: {
    id: "productivity",
    name: "Productivity",
    icon: SvgProductivity,
    description: {
      short: "Amp up productivity with efficient tools",
      long: "Supercharge your efficiency. Discover a suite of productivity tools designed to enhance your workflow.",
    },
    position: 21,
  },
  reference: {
    id: "reference",
    name: "Reference",
    icon: SvgReference,
    description: {
      short: "Explore a wealth of information and references",
      long: "Access a world of knowledge. Explore diverse references and informational sources in our Reference category.",
    },
    position: 22,
  },
  shopping: {
    id: "shopping",
    name: "Shopping",
    icon: SvgShopping,
    description: {
      short: "Shop smartly and find the best deals",
      long: "Shop smartly and save big. Discover deals, compare prices, and find the best products with our Shopping apps.",
    },
    position: 23,
  },
  social: {
    id: "social",
    name: "Social",
    icon: SvgUsers,
    description: {
      short: "Connect and share with friends and communities",
      long: "Connect, share, and engage. Explore a variety of social apps to stay connected with friends and communities.",
    },
    position: 24,
  },
  sports: {
    id: "sports",
    name: "Sports",
    icon: SvgSports,
    description: {
      short: "Stay in the game with sports news and updates",
      long: "Stay in the game. Get the latest updates, scores, and news from the world of sports in our dedicated Sports category.",
    },
    position: 25,
  },
  travel: {
    id: "travel",
    name: "Travel",
    icon: SvgTravel,
    description: {
      short: "Plan your next adventure with travel apps",
      long: "Plan your adventure with ease. Explore travel apps for seamless journey planning and unforgettable experiences.",
    },
    position: 26,
  },
  utilities: {
    id: "utilities",
    name: "Utilities",
    icon: SvgUtilities,
    description: {
      short: "Essential tools to optimize your device",
      long: "Optimize your device. From security to performance, find essential tools to enhance your device's functionality.",
    },
    position: 27,
  },
  weather: {
    id: "weather",
    name: "Weather",
    icon: SvgWeather,
    description: {
      short: "Stay ahead of the weather with accurate forecasts",
      long: "Stay ahead of the forecast. Get accurate weather updates and plan your days with our reliable Weather category.",
    },
    position: 28,
  },
}

export const PrimaryCategories = omit(Categories, [
  categoryAll.id,
  categoryFeatured.id,
  categoryNew.id,
]) as CategoryList

export function getPrimaryCategoryFromPath(
  pathname: string,
): PrimaryCategory | undefined {
  const match = pathname.match(/\/categories\/([^/]+)/)
  if (!match) return undefined
  return Categories[match[1]] || undefined
}

export function getCategoryFromPath(pathname: string): Category | undefined {
  const match = pathname.match(/\/categories\/([^/]+)(\/([^/]+))?/)
  if (!match) return undefined
  if (match[3])
    return Categories[match[1]]?.subCategories?.[match[3]] || undefined
  return Categories[match[1]] || undefined
}

export function isPrimaryCategory(
  category: Category | null,
): category is PrimaryCategory {
  if (!category) return false
  return !!(category as PrimaryCategory).description
}

export function isSubCategory(category: Category): category is SubCategory {
  return !isPrimaryCategory(category)
}

export function isCategoryValid(primary: string, sub?: string): boolean {
  return (
    !!Categories[primary] &&
    (!sub || !!Categories[primary].subCategories?.[sub])
  )
}
