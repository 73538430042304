import * as React from "react"
import type { SVGProps } from "react"
const SvgLifestyle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M6.17 1.868a5.125 5.125 0 0 0-3.629 1.507c-2.032 2.033-2.095 5.41.346 7.896l6.671 6.671a.625.625 0 0 0 .884 0l6.667-6.667.004-.004c2.44-2.487 2.378-5.863.346-7.896a5.126 5.126 0 0 0-7.26 0l-.002.003-.197.2-.197-.2-.002-.002a5.125 5.125 0 0 0-3.63-1.508M4.686 3.414a3.875 3.875 0 0 1 4.23.843l.64.649a.625.625 0 0 0 .89 0l.64-.648v-.001a3.875 3.875 0 0 1 5.489 0v.002c1.26 1.26 1.571 3.287.45 5.116H13.33L12.24 6.837a.625.625 0 0 0-1.175.075L9.872 11.09l-.968-2.177a.625.625 0 0 0-1.13-.026l-.244.488H2.976c-1.121-1.83-.81-3.857.45-5.116v-.001c.36-.361.788-.648 1.259-.844m-.676 7.211L10 16.616l5.991-5.991h-3.074a.625.625 0 0 1-.575-.379l-.557-1.3-1.184 4.142a.625.625 0 0 1-1.172.083l-1.175-2.645a.625.625 0 0 1-.337.099z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgLifestyle
