import * as React from "react"
import type { SVGProps } from "react"
const SvgEducation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M10.28 3.608a.625.625 0 0 0-.56 0L1.387 7.774a.625.625 0 0 0 0 1.118l2.988 1.494v3.78c0 .166.066.325.183.443 1.394 1.393 3.443 2.058 5.442 2.058s4.048-.665 5.442-2.058a.625.625 0 0 0 .183-.442v-3.78l2.083-1.042v3.988a.625.625 0 0 0 1.25 0v-5a.625.625 0 0 0-.345-.559zm4.453 5.827a.607.607 0 0 0-.024.012L10 11.8 5.29 9.447a.62.62 0 0 0-.023-.012L3.064 8.333 10 4.865l6.936 3.468zm-9.108 1.576L9.72 13.06a.625.625 0 0 0 .56 0l4.095-2.048v2.887c-1.106.994-2.718 1.519-4.375 1.519s-3.27-.525-4.375-1.519z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgEducation
