import * as React from "react"
import type { SVGProps } from "react"
const SvgDesign = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M1.225 1.225a.625.625 0 0 1 .6-.163l12.072 2.914a.625.625 0 0 1 .466.485l1.02 5.105.008-.008a.625.625 0 0 1 .884 0l2.5 2.5a.625.625 0 0 1 0 .884l-5.833 5.833a.625.625 0 0 1-.884 0l-2.5-2.5a.625.625 0 0 1 0-.884l.008-.007-5.105-1.021a.625.625 0 0 1-.485-.466L1.062 1.824a.627.627 0 0 1 .163-.6m5.94 6.824-4.37-4.37 2.302 9.536 5.53 1.107 3.694-3.694-1.106-5.53L3.68 2.794l4.37 4.37a2.292 2.292 0 1 1-.884.884m1.255.391a1.042 1.042 0 1 0 1.493 1.453A1.042 1.042 0 0 0 8.42 8.44m7.024 2.833-.002.002-4.167 4.167-.002.002-.39.39L12.5 17.45l4.95-4.95-1.617-1.616z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgDesign
