import * as React from "react"
import type { SVGProps } from "react"
const SvgNavigation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M14.111 6.704a.625.625 0 0 0-.816-.815L8.29 7.949a.625.625 0 0 0-.34.34l-2.06 5.006a.625.625 0 0 0 .816.816l5.006-2.06a.625.625 0 0 0 .34-.34zm-6.496 5.68 1.39-3.378 3.38-1.391-1.39 3.38z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M10 1.042a8.958 8.958 0 1 0 0 17.916 8.958 8.958 0 0 0 0-17.916M2.292 10a7.708 7.708 0 1 1 15.416 0 7.708 7.708 0 0 1-15.416 0"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgNavigation
