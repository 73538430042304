import * as React from "react"
import type { SVGProps } from "react"
const SvgFood = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M2.5 1.042c.345 0 .625.28.625.625V7.5c0 .572.47 1.042 1.042 1.042h1.041V1.667a.625.625 0 0 1 1.25 0v6.875H7.5A1.042 1.042 0 0 0 8.542 7.5V1.667a.625.625 0 1 1 1.25 0V7.5A2.292 2.292 0 0 1 7.5 9.792H6.458v8.541a.625.625 0 0 1-1.25 0V9.792H4.167A2.297 2.297 0 0 1 1.875 7.5V1.667c0-.345.28-.625.625-.625" />
    <path
      fillRule="evenodd"
      d="M17.5 1.042a4.792 4.792 0 0 0-4.792 4.791v5A2.297 2.297 0 0 0 15 13.125h1.875v5.208a.625.625 0 0 0 1.25 0V1.667a.625.625 0 0 0-.625-.625m-.625 1.305v9.528H15c-.572 0-1.042-.47-1.042-1.042v-5a3.542 3.542 0 0 1 2.917-3.486"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgFood
